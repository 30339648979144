import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useNumberFormatter } from "@/lib/hooks/use-currency-formatter";
import { useEffect, useState } from "react";

export function CurrencyInput(
	props: React.ComponentPropsWithRef<typeof Input>,
) {
	const { formatCurrency } = useNumberFormatter();

	const [showPopover, setShowPopover] = useState(false);
	const [displayedValue, setDisplayedValue] = useState(
		props.defaultValue
			? formatCurrency(
					Number(props.defaultValue?.toString().replaceAll(".", "")),
				)
			: props.value
				? formatCurrency(Number(props.value?.toString().replaceAll(".", "")))
				: "",
	);
	const [actualValue, setActualValue] = useState(
		props.defaultValue ?? props.value,
	);

	useEffect(() => {
		setDisplayedValue(props.value as string);
		setActualValue(props.value);
	}, [props.value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDisplayedValue(e.target.value);
		setActualValue(e.target.value);
		props.onChange?.(e);
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		setShowPopover(false);
		props.onBlur?.(e);
		let value = displayedValue;
		if (value.startsWith("0")) {
			value = value.slice(1);
		}
		value = value.replaceAll(".", "");

		setDisplayedValue(formatCurrency(Number(value)));
	};

	const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setShowPopover(true);
		props?.onFocus?.(e);
		const value = displayedValue;
		setDisplayedValue(value.replaceAll(".", ""));
		if (displayedValue === "0") {
			setDisplayedValue("");
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (
			!/[0-9]/.test(e.key) &&
			!["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"].includes(e.key)
		) {
			e.preventDefault();
		}
	};

	return (
		<Popover open={showPopover}>
			<PopoverTrigger className="w-full">
				<Input
					{...props}
					name={undefined}
					value={displayedValue}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					onKeyDown={handleKeyDown}
				/>
				<input type="hidden" name={props.name} value={actualValue} />
			</PopoverTrigger>
			<PopoverContent
				onOpenAutoFocus={(e) => e.preventDefault()}
				align="start"
				side="top"
				className="w-max bg-secondary"
			>
				{formatCurrency(displayedValue)}
			</PopoverContent>
		</Popover>
	);
}
